import { Injectable } from '@angular/core';
import { AbstractRestService } from '../../../core/services';
import { Cache } from '../../../core/decorators';
import { Observable, Subject } from 'rxjs';
import { ILanguage } from '../interfaces/ILanguage';
import { Apps, StoreCacheKeys } from '../../../core/enums';
import { ISnippet } from '../interfaces/ISnippet';
import { HttpParams } from '@angular/common/http';
import { LanguageStoreService } from './LanguageStoreService';
import { Filter, IPagination, Search, SortingTypes } from '@cinetixx/cinetixx-ui';
import { HttpUtils } from '../../../core/utils/HttpUtils';
import { tap } from 'rxjs/operators';
import { IRange } from '../../../core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SnippetsService extends AbstractRestService<ISnippet> {

  public getSnippetsFromRange$: Subject<ISnippet[]> = new Subject();

  protected readonly basePath = '/snippets';

  public constructor(
    private readonly _languageStoreService: LanguageStoreService,
  ) {
    super();
  }

  @Cache<ILanguage[]>(StoreCacheKeys.GetAllLanguages)
  public getAllLanguages(): Observable<ILanguage[]> {
    return this.apiService.get(`${ this.basePath }/languages/all`);
  }

  @Cache<ISnippet[]>(StoreCacheKeys.GetAllSnippets)
  public getAllSnippets(language: string): Observable<ISnippet[]> {
    const params = new HttpParams({
      fromObject: {
        language: language,
        app: Apps.Admin
      }
    });

    return this.apiService.get(`${this.basePath}/all`, params);
  }

  public getPaginatedSnippetsAll(
    page: number,
    results: number,
    sortField: string,
    sortOrder: SortingTypes,
    search: Search = [],
    filter: Filter = [],
    ...args
  ): Observable<IPagination<ISnippet>> {
    let params = HttpUtils.prepareQueryableParams(
      page,
      results,
      sortField,
      sortOrder,
      search,
      filter
    );
    const apps = ['manager', 'screener', 'entry', 'admin'];

    if (args.some(x => !x.value)) {
      for (const appName of apps) {
        const appValue = args?.find(x => x.name === appName)?.value ?? true;

        if (appValue) {
          switch (appName) {
            case 'manager':
              params = params.append('Filter', 'appId:2');
              break;
            case 'screener':
              params = params.append('Filter', 'appId:4');
              break;
            case 'entry':
              params = params.append('Filter', 'appId:5');
              break;
            case 'admin':
              params = params.append('Filter', 'appId:1');
              break;
            default:
              break;
          }
        }
      }
    }

    return this.apiService.get(`${this.basePath}`, params);
  }

  public getSnippetsFromRange(range: IRange, employeeId: number): Observable<ISnippet[]> {
    const params = new HttpParams({
      fromObject: {
        ...range,
        employeeId
      }
    });

    return this.apiService.get(`${this.basePath}/from-range`, params).pipe(
      tap((res) => this.getSnippetsFromRange$.next(res))
    );
  }

  public addSnippet(namespace: string, value: string): Observable<ISnippet> {
    const body = {
      namespace,
      value,
      snippetLanguageId: this._languageStoreService.language.id,
      appId: Apps.Admin
    };

    return this.apiService.post(`${this.basePath}`, body);
  }

  public importSnippets(snippets: ISnippet[]) {
    return this.apiService.post(`${ this.basePath }/import`, {snippets});
  }
}
