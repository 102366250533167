import { Apps } from '../app/core/enums';

export const environment = {
  production: true,
  isSW: false,
  version: 'v2.40.8',
  accountNumber: 'DE0000',
  cloudFrontUrl: 'https://images.cinetixx.com',
  managerUrl: 'https://manager.cinetixx.de',
  apiUrl: 'https://api.backend.cinetixx.com',
  wsUrl: 'https://api.backend.cinetixx.com/signalr',
  appUrl: 'https://admin.cinetixx.de',
  app: Apps.Admin,
  brand: 'CX',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  dsn: 'https://3c86e2e47179413484bdce9abeffad57@sentry.cinetixx.com/22',
  versionPrefix: '',
  trailersUrl: 'https://trailers.cinetixx.com/',
  releaseNotesUrl: 'https://cinetixx.de/assets/pdf/',
  postersLink: 'https://images.cinetixx.com/posters'
};
