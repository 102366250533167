import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor, HttpTokenInterceptor } from './interceptors';
import {
  DateFormatPipe,
  DateTimeFormatPipe,
  DictionaryIdPipe,
  DictionaryValuePipe,
  IncludesPipe,
  NewsUrlPipe,
  NumberToEuroPipe,
  NumberToLocaleFormatPipe,
  NumberToPercentPipe,
  ReplacePipe,
  StringPipe,
  TimeFormatPipe,
  UrlTrailerPipe
} from './pipes';
import {
  NumbersInputOnlyDirective,
  PreloadImageDirective,
  ShowAuthedDirective,
  ShowForRoleDirective
} from './directives';

@NgModule({
  declarations: [
    DateTimeFormatPipe,
    DateFormatPipe,
    TimeFormatPipe,
    NumberToEuroPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    NumbersInputOnlyDirective,
    StringPipe,
    IncludesPipe,
    NewsUrlPipe,
    UrlTrailerPipe,
    DictionaryValuePipe,
    DictionaryIdPipe,
    NumberToPercentPipe,
    NumberToLocaleFormatPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  exports: [
    DateTimeFormatPipe,
    DateFormatPipe,
    NumberToEuroPipe,
    TimeFormatPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    NumbersInputOnlyDirective,
    StringPipe,
    IncludesPipe,
    NewsUrlPipe,
    UrlTrailerPipe,
    DictionaryIdPipe,
    DictionaryValuePipe,
    NumberToPercentPipe,
    NumberToLocaleFormatPipe
  ]
})
export class CoreModule { }
