import { StaticDependenciesService } from '../services';
import { Apps } from '../enums';

export const trans = String.prototype.trans = function(namespace: string, replacements:[string, string|number][] = [], optionalValue = ''): string {
  const defaultSnippets = require(
    `./../../language/core/configs/snippets/${ StaticDependenciesService.languageStoreService.language.token }.json`
  );
  const snippets = StaticDependenciesService.snippetsStoreService.snippets;
  const fSnippet = snippets.find(snippet => {
    return (
      snippet.namespace?.toLowerCase() === namespace?.toLowerCase() &&
      snippet.app === Apps.Admin &&
      snippet.languageId === StaticDependenciesService.languageStoreService.language.id
    );
  });

  let defaultValue: string = ((typeof this) != 'object') ? this as string : optionalValue;

  if (fSnippet) {
    return replaceTokens(fSnippet.value ?? '', replacements);
  } else if (!defaultValue) {
    const defaultNamespace = Object.keys(defaultSnippets).find(value => namespace.includes(value));

    if (defaultNamespace) {
      defaultValue = defaultSnippets[defaultNamespace];
    }
  }

  if (StaticDependenciesService.tokenService.token && location.hostname !== 'localhost') {
    StaticDependenciesService.snippetsService.addSnippet(namespace, defaultValue).subscribe(
      snippet => {
        StaticDependenciesService.snippetsStoreService.snippets = [...snippets, snippet];
      }
    );
  }

  return replaceTokens(defaultValue, replacements);
};

function replaceTokens(text: string, replacements:[string,string|number][]) {
  for (const [key, value] of replacements) {
    text = text.replaceAll(`[[${ key }]]`, value?.toString());
  }

  return text;
}

String.prototype.langUrl = function(): string {
  return `/${ StaticDependenciesService.languageStoreService.language.token }${ this }`;
};
