export {};

Number.prototype.toLocaleFormat = function(): string {
  return this.toLocaleString('de-DE');
};

Number.prototype.toEuro = function(minimumFractionDigits = 2, maximumFractionDigits = 2): string {
  return `${ this.toLocaleString('de-DE', { minimumFractionDigits, maximumFractionDigits }) } €`;
};

Number.prototype.toPercent = function(): string {
  return `${ this.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %`;
};
