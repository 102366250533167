import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { HttpEvent, HttpParams } from '@angular/common/http';
import { UploadFileTypes } from '../enums/UploadFileTypes';
import { ImageTypes } from '@cinetixx/cinetixx-ui';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private readonly _basePath = '/tools';

  public constructor(
    private readonly _apiService: ApiService
  ) {}

  public uploadFile(uploadFileTypes: UploadFileTypes[], file: File): Observable<HttpEvent<any>> {
    return this.upload<UploadFileTypes>(uploadFileTypes, file, `${ this._basePath }/files`);
  }

  public uploadImage(imageTypes: ImageTypes[], file: File): Observable<HttpEvent<any>> {
    return this.upload<ImageTypes>(imageTypes, file, '/images');
  }

  public getUploadUrl(file: any, fileType?: UploadFileTypes): Observable<HttpEvent<any>> {
    const params = new HttpParams()
    .append('fileName', file.name)
    .append('fileType', fileType ? fileType : (file.type.includes('image') ? UploadFileTypes.ScreenerImage : UploadFileTypes.ScreenerVideo));

    return this._apiService.get(`${this._basePath}/files/upload-url`, params);
  }

  public uploadFileToUrl(file: File, url: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this._apiService.uploadFileToPredefinedUrl(url, file, params);
  }

  private upload<T>(types: T[], file: File, path: string): Observable<HttpEvent<any>> {
    let params = new HttpParams();

    if (types.length > 0) {
      for (const type of types) {
        params = params.append('type', type.toString());
      }
    }

    const formData = new FormData();

    formData.append('filePayload', file);

    return this._apiService.uploadFile(path, formData, params);
  }
}
