export {};

Array.prototype.sortByKey = function(key: string, sortingType: 'asc' | 'desc' = 'asc'): any[] {
  if (key && sortingType) {
    const sortedData = this.sort(
      (a: any, b: any) =>
        (typeof a[key] === 'string'
          ? a[key]?.toUpperCase()
          : a[key]) < (typeof b[key] === 'string'
          ? b[key]?.toUpperCase()
          : b[key]) ? -1 : 1
    );
    return sortingType === 'asc' ? sortedData : sortedData.reverse();
  } else {
    return this;
  }
};

Array.prototype.sumByKey = function(key: string): number {
  return this.reduce((prev, current) => prev += current[key], 0);
};
