import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly _httpClient: HttpClient
  ) {}

  public get(path: string, params: HttpParams | {} = new HttpParams()): Observable<any> {
    return this._httpClient.get(`${ environment.apiUrl }${ path }`, { params });
  }

  public post(path: string, body = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.post(
      `${ environment.apiUrl }${ path }`,
      JSON.stringify(body),
      {
        params,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }

  public postFormData(path: string, formData: FormData, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.post(
      `${ environment.apiUrl }${ path }`,
      formData,
      {
        params,
      }
    );
  }

  public uploadFile(path: string, formData: FormData, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.post(
      `${ environment.apiUrl }${ path }`,
      formData,
      {
        params,
        observe: 'events',
        reportProgress: true
      }
    );
  }

  public uploadFileToPredefinedUrl(url: string, file: File, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.put(url, file, {
      params,
      observe: 'events',
      reportProgress: true,
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      }
    });
  }

  public getFile(path: string, contentType: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this._httpClient.get(`${ environment.apiUrl }${ path }`,
      {
        params,
        headers: {
          'Accept': contentType
        },
        responseType : 'blob',
      },
    );
  }

  public put(path: string, body = {}): Observable<any> {
    return this._httpClient.put(
      `${ environment.apiUrl }${ path }`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }

  public delete(path: string): Observable<any> {
    return this._httpClient.delete(`${ environment.apiUrl }${ path }`);
  }
}
