import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { IdentityService } from './IdentityService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private readonly _basePath = '/tools/user-preferences';

  public constructor(
    private readonly _apiService: ApiService,

    protected readonly _identityService: IdentityService
  ) {}

  public getPreferenceByKey(key: string): Observable<any> {
    return this._apiService.get(`${this._basePath}/get-user-preferences?key=${key}`).pipe(
      map((res: { preferences: string }) => {
        return res?.preferences ? JSON.parse(res.preferences) : null;
      })
    );
  }

  public savePreferences(key: string, preferences: any): Observable<void> {
    return this._apiService.post(`${this._basePath}/save-user-preferences`, {
      preferences: typeof preferences !== 'string' ? JSON.stringify(preferences) : preferences,
      key
    });
  }
}
