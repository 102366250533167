import { Injectable } from '@angular/core';
import { ApiService, TokenService } from '../../../core/services';
import { Observable } from 'rxjs';
import { IResetPassword, ISetNewPassword, IIdentity, ILogin } from '../interfaces';
import { IdentityService, NavigationService } from '../../../core/services';
import { Router } from '@angular/router';
import { LanguageStoreService } from '../../../language/core/services/LanguageStoreService';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _basePath = '/employees';

  public constructor(
    private readonly _apiService: ApiService,
    private readonly _tokenService: TokenService,
    private readonly _identityService: IdentityService,
    private readonly _router: Router,
    private readonly _languageStoreService: LanguageStoreService,
    private readonly _navigationService: NavigationService,
  ) {}

  public login(body: ILogin): Observable<IIdentity> {
    return this._apiService.post(`${ this._basePath }/login`, body);
  }

  public getStatus(): Observable<string> {
    return this._apiService.get(`${ this._basePath }/status`);
  }

  public refreshToken(): Observable<IIdentity> {
    const body = {
      refreshToken: this._tokenService.refreshToken
    };

    return this._apiService.post(`${ this._basePath }/refresh-token`, body);
  }

  revokeTokens(): void {
    const body = {refreshToken: this._tokenService.refreshToken};
    this._apiService.post(this._basePath + '/logout', body).subscribe();
  }

  public resetPassword(data: IResetPassword) {
    return this._apiService.post(`${this._basePath}/reset-password`, data);
  }

  public setPassword(data: ISetNewPassword) {
    return this._apiService.post(`${this._basePath}/set-password`, data);
  }

  public purgeAuth(withRedirect = false) {
    if (this._identityService.identity) {
      this.revokeTokens();
    }

    this._identityService.destroyIdentity();

    if (withRedirect) {
      this._router.navigateByUrl('/auth/login'.langUrl());
    }
  }

  public logout(): void {
    const backPath = location.pathname.replace(`/${ this._languageStoreService.language.token }`, '');
    this._identityService.destroyIdentity();
    this._navigationService.navigateByUrl(`/auth/login?back=${ backPath }`);
  }
}
