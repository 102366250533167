import { ApiService } from './ApiService';
import { map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { IDictionary } from '../interfaces';

export abstract class AbstractBaseDictionariesService<T> {

  protected readonly apiService = inject(ApiService);
  protected abstract readonly basePath: string;

  protected getDictionary(type: T, additionalParams = {}): Observable<IDictionary> {
    const params = new HttpParams({
      fromObject: {
        'dictionary': type.toString(),
        ...additionalParams
      }
    })
    return this.apiService.get(
      this.basePath,
      params
    ).pipe(map(res => res[0]));
  }
}
