export enum StoreCacheKeys {
  GetAllLanguages = 'getAllLanguages',
  GetAllSnippets = 'getAllSnippets',
  GetEmployees = 'getEmployers',
  GetMyProfile = 'getMyProfile',
  GetMyAccount = 'getMyAccount',
  GetMovieDiscriptors = 'getMovieDiscriptors',
  GetCinemas = 'getCinemas',
  GetMyCinemas = 'getMyCinemas',
  GetMyCinemasWithAuditoriums = 'getMyCinemasWithAuditoriums',
  GetDevices = 'getDevices',
  GetTechnicalDevices = 'getTechnicalDevices',
  GetReleaseNotes = 'getReleaseNotes',
  GetActivationOrders = 'getActivationOrder',
  GetNotedMovies = 'getNotedMovies',
  GetDistributors = 'getDistributors',
  GetActiveCashSessions = 'getActiveCashSessions',
  GetNews = 'getNews',
  GetDashboard = 'getDashboard',
  GetLatest = 'getLatest',
  GetGenres = 'getGenres',
  GetAgeRatings = 'getAgeRatings',
  GetVersionTypes = 'getVersionTypes',
  GetCastTypes = 'getCastTypes',
  GetMovieTypes = 'getMovieTypes',
  GetAwsDashboardUrl = 'getAwsDashboardUrl',
  CountriesMovie = 'countriesMovie',
  Countries = 'countries',
  GetAdmins = 'getAdmins',
  GetTerminalTypes = 'getTerminalTypes',
  GetKioskLayoutTypes = 'getKioskLayoutTypes',
  GetMandators = 'getMandators',
  GetKiosks = 'getKiosks',
  GetTableExports = 'getTableExports',
  MinCashMigrationVersion = 'minCashMigrationVersion',
  GetAllReportTypes = 'getAllReportTypes',
}
